import * as React from "react"
import LayoutSystemsBouwInfra from "../../components/layout-systems-bouw-infra";

const SystemPage = () => {
  return (
    <LayoutSystemsBouwInfra>

    </LayoutSystemsBouwInfra>
  )
}

export default SystemPage